import {createReducer, on} from '@ngrx/store';

import {AuthActions} from '../action-types';
import {User} from "../../../shared/models/user";


export interface AuthState {
  user: User | undefined;
  refreshingToken: boolean;
}

export const initialAuthState: AuthState = {
  user: undefined,
  refreshingToken: false
};

// il reducer è una funzione che prende in input uno stato e un'azione e restituisce un nuovo stato, definisce come cambia lo stato in base all'azione
// in pratica dice allo store come gestire le azioni
export const authReducer = createReducer(
  initialAuthState,

  on(AuthActions.login, (state, action) => {
    return {
      user: action.user,
      refreshingToken: false
    }
  }),

  on(AuthActions.refreshToken, (state, action) => {
    return {
      user: undefined,
      refreshingToken: true
    }
  }),


  on(AuthActions.logout, (state, action) => {
    return {
      user: undefined,
      refreshingToken: false
    }
  })
);

